<template>
  <el-container>
    <el-aside>
      <el-scrollbar>
        <left-menu />
      </el-scrollbar>
    </el-aside>
    <el-main>
      <el-scrollbar>
        <router-view />
      </el-scrollbar>
    </el-main>
  </el-container>
</template>

<script>
import leftMenu from "./left-menu/index.vue";
export default {
  components: { leftMenu },
};
</script>

<style lang="scss" scoped>
.el-container {
  height: 100vh;
  .el-aside {
    padding-right: 10px;
    .el-scrollbar {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
  .el-main{
    padding: 0;
    .el-scrollbar{
      padding: 0 10px;
    }
  }
}
</style>
