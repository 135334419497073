<template>
  <el-sub-menu
    v-if="items.children && items.children.length"
    :index="items.path"
  >
    <template #title>{{ items.name }}</template>
    <menuItem v-for="(item, i) in items.children" :key="i" :items="item" />
  </el-sub-menu>
  <el-menu-item v-else :index="items.path">{{ items.name }}</el-menu-item>
</template>

<script>
export default {
  name: "menuItem",
  props: ["items"],
};
</script>
