export default [
  {
    path: '/index',
    name: '首页',
    component: () => import( /* webpackChunkName: "views" */ '@/views/index/index')
  }, {
    path: "jiamigongju",
    name: "加密工具",
    redirect: "/guomi",
    children: [{
      path: '/guomi',
      name: '国密加密',
      redirect: '/guomi/sm2',
      children: [
        {
          path: '/guomi/sm2',
          name: 'SM2',
          component: () => import( /* webpackChunkName: "views" */ '@/views/gm/sm2')
        },
        {
          path: '/guomi/sm3',
          name: 'SM3',
          component: () => import( /* webpackChunkName: "views" */ '@/views/gm/sm3')
        },
        {
          path: '/guomi/sm4',
          name: 'SM4',
          component: () => import( /* webpackChunkName: "views" */ '@/views/gm/sm4')
        },
      ]
    }, {
      path: '/md5',
      name: 'MD5',
      component: () => import( /* webpackChunkName: "views" */ '@/views/md5/index')
    }]
  }
]