import Layout from '@/page/index/index'
import indexChildren from "./view"
export default [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: indexChildren
  },
  {
    path: '/error',
    name: 'error',
    redirect: '404',
    component: Layout,
    children: [
      {
        path: '404',
        name: '404',
        component: () => import( /* webpackChunkName: "views" */ '@/views/error/404')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/error/404',
  }
]