<template>
  <el-menu
    :default-active="activeIndex"
    @open="() => {}"
    @close="() => {}"
    @select="() => {}"
    :router="true"
  >
    <menuItem v-for="(items, index) in menuList" :key="index" :items="items" />
  </el-menu>
</template>

<script>
import menuList from "@/router/view";
import menuItem from "./menu-item.vue";
export default {
  components: { menuItem },
  data() {
    return {
      menuList: menuList,
      activeIndex: "",
    };
  },
  mounted() {
    this.activeIndex = this.$route.path;
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  border-right: 0;
}
</style>
