import { createRouter, createWebHashHistory } from 'vue-router'
import Store from '../store/';
import RouterPlugin from './RouterPlugin'; //封装的路由控制方法
import RouterEach from './RouterEach';

import routes from './page'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 初始化和注册 RouterPlugin
RouterPlugin.install({
  router,
  store: Store,
  keepAlive: false,
});

// router.$util.formatRoutes(Store.getters.menuList); // 动态路由核心方法
router.addRoute(routes);

router.beforeEach(RouterEach.before);
router.afterEach(RouterEach.after);

export default router