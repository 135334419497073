/**
 * 全站权限配置
 *
 */
import router from './index'
// import store from '../store'
export default {
  before: () => {
    // console.log(to, from);
    // store.
    return true;
  },
  after: (to) => {
    // console.log(to, from);
    router.$util.setTitle(to.name)
  }
}