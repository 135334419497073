let RouterPlugin = function() {
  this.$router = null;
  this.$store = null;

};
RouterPlugin.install = function(option = {}) {
  this.$router = option.router;
  this.$store = option.store;


  this.$router.$util = {
    //全局配置
    routerList: [],
    meta: {},
    safe: this,
    // 设置标题
    setTitle: (title) => {
      document.title = title;
    },
    // 处理动态路由
    formatRoutes(list = [], outside = true) {
      if (!list || list.length == 0) return;
      const resRouter = [];
      // console.log(list);
      // return;
      list.forEach(item => {
        const itemRouter = {
          name: item.name,
          meta: item.meta,
          path: "/" + item.filepath,
          redirect: ""
        }
        if (item.children && item.children.length) {
          Object.assign(itemRouter, {
            component: () => require.ensure([], require => require(`@/page/index`)),
            children: this.formatRoutes(item.children, false)
          });
        } else {
          Object.assign(itemRouter, {
            component: () => require.ensure([], require => require(`@/views/${item.filepath}.vue`)),
          });
        }
        resRouter.push(itemRouter)
        if (outside) {
          // 如果是外部调用的，则动态添加到路由中
          this.safe.$router.addRoute(itemRouter)
        }
      })
      if (!outside) {
        // 如果不是外部调用，则返回处理后的路由列表
        return resRouter;
      }
    }
  }
}
export default RouterPlugin;